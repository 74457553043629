const capitalizeNames = (names: string[]) => {
  return names.map((name) => {
    const firstLetter = name[0].toUpperCase();
    const remaining = name.slice(1).toLowerCase();
    return firstLetter + remaining;
  });
};

const formatName = (name: string) => {
  const names = capitalizeNames(name.split(' '));
  if (names.length === 1) {
    return [names[0], ''];
  }
  if (name.length === 2) {
    return [names[0], names[1]];
  } else {
    return [names[0], names[names.length - 1]];
  }
};
const validateName = (name: string) => {
  if (!name) return false;
  if (name.match(/invalid name/i)) {
    return false;
  }
  if (!/^[A-Za-z\s]*$/.test(name) || /\s$/.test(name)) {
    return false;
  }
  if (name.length > 800) {
    return false;
  }
  return true;
};

const validateEmail = (email: string) => {
  if (!email) {
    return false;
  }
  return email
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};

export { capitalizeNames, formatName, validateName, validateEmail };
