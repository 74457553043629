import React from 'react';
import { useState, useRef } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import style from './SubscribeForm.module.scss';
import { Button } from '../common';
import { formatName, validateName, validateEmail } from '../../lib';

enum FormStatus {
  IDLE,
  PENDING,
  COMPLETE,
}

const subscribeUser = async (fname: string, lname: string, email: string) => {
  try {
    fetch(process.env.REACT_APP_SUBSCRIBE_URL!, {
      method: 'POST',
      body: JSON.stringify({ fname, lname, email }),
    });
  } catch (error) {
    console.error(error);
  }
};

const SubscribeForm = () => {
  const [formStatus, setFormStatus] = useState(FormStatus.IDLE);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const nameRef = useRef<HTMLInputElement>(null);
  const emailRef = useRef<HTMLInputElement>(null);
  const reCAPTCHARef = useRef<ReCAPTCHA>(null);

  const validateForm = (name: string, email: string) => {
    let isValid = true;
    if (!validateName(name)) {
      isValid = false;
      nameRef.current?.classList.add('invalid-input');
      setTimeout(() => {
        nameRef.current?.classList.remove('invalid-input');
      }, 500);
    }
    if (!validateEmail(email)) {
      isValid = false;
      emailRef.current?.classList.add('invalid-input');
      setTimeout(() => {
        emailRef.current?.classList.remove('invalid-input');
      }, 500);
    }
    return isValid;
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    const token = reCAPTCHARef.current?.getValue();
    const res = await fetch(process.env.REACT_APP_VALIDATE_HUMAN_URL!, {
      method: 'POST',
      body: JSON.stringify({ token }),
    });
    const { success } = await res.json();
    if (success) {
      const [first, last] = formatName(name);
      subscribeUser(first, last, email);
      setFormStatus(FormStatus.COMPLETE);
    } else {
      reCAPTCHARef.current?.reset();
    }
  };

  const handleValidate = () => {
    if (validateForm(name, email)) {
      setFormStatus(FormStatus.PENDING);
    }
  };

  return (
    <form name="mailing" onSubmit={handleSubmit} className={'custom-form'}>
      {formStatus === FormStatus.PENDING && (
        <>
          <div className={style.recaptcha}>
            <ReCAPTCHA
              sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY!}
              ref={reCAPTCHARef}
            />{' '}
          </div>
          <Button options={{ type: 'submit' }}>confirm</Button>{' '}
        </>
      )}
      {formStatus === FormStatus.IDLE && (
        <>
          <label>
            <input
              ref={nameRef}
              type="text"
              placeholder="Full Name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              className={'captitalize'}
            />
          </label>
          <label>
            <input
              ref={emailRef}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              type="email"
              placeholder="Email"
            />
          </label>
          <Button options={{ type: 'button' }} cb={handleValidate}>
            join
          </Button>{' '}
        </>
      )}
      {formStatus === FormStatus.COMPLETE && (
        <>
          <p className={style.p}>Thank you {name}!</p>
          <p className={style.p}>
            Please visit your inbox to confirm your email {email}
          </p>
        </>
      )}
    </form>
  );
};

export default SubscribeForm;
