import logo from '../../../static/logo.png';
import style from './Hero.module.scss';
import { Section } from '../../../components/common';
import Colour from '../../../constants/Colour';

const Hero = () => {
  return (
    <Section colour={Colour.PRIMARY} className={style.container}>
      <div className={style.hero_img} />
      <div className={style.hero_content}>
        <img className={style.logo} src={logo} alt="noisy band logo" />
        <h1 className={style.title}>Funk - Jazz - Soul</h1>
      </div>
    </Section>
  );
};

export default Hero;
